import { TrackEventType } from './types/adTrackingService';
import IAdEvents from './types/adEvents';
import IMediaEvents from './types/mediaEvents';
import VastProperties from './types/vastProperties';

import { InteractionType } from './consts/eventTypes';
import * as TrackingConsts from './consts/adTrackingConstants';
import { VideoPlayerState } from './consts/videoPlayerState';


export interface EventTracker {
  trackEvent: (eventName: string, params: TrackEventType) => void;
  loaded: (vastProperties: VastProperties) => void;
}

export default class AdEventTracker implements EventTracker {
  private adEvents: IAdEvents;
  private mediaEvents: IMediaEvents;

  constructor(adEvents: IAdEvents, mediaEvents: IMediaEvents) {
    this.adEvents = adEvents;
    this.mediaEvents = mediaEvents;
  }

  public loaded(vastProperties: VastProperties): void {
    this.adEvents.loaded(vastProperties);
  }

  public trackEvent (eventName: string, params: TrackEventType): void {
    switch (eventName) {
      case TrackingConsts.EVENT_NAME_IMPRESSION:
          return this.impressionOccurred();
      case TrackingConsts.EVENT_NAME_CLICK_TRACKING:
          return this.adUserInteraction(InteractionType.CLICK);
      case TrackingConsts.EVENT_NAME_PAUSE:
          return this.pause();
      case TrackingConsts.EVENT_NAME_RESUME:
          return this.resume();
      case TrackingConsts.EVENT_NAME_START: 
        return this.start(params);
      case TrackingConsts.EVENT_NAME_FIRST_QUARTILE:
          return this.firstQuartile();
      case TrackingConsts.EVENT_NAME_MID_POINT:
          return this.midpoint();
      case TrackingConsts.EVENT_NAME_THIRD_QUARTILE:
          return this.thirdQuartile();
      case TrackingConsts.EVENT_NAME_COMPLETE:
          return this.complete();
      case TrackingConsts.EVENT_NAME_VOLUME_CHANGE:
        return this.volumeChange(params);
      case TrackingConsts.EVENT_NAME_PLAYER_EXPAND:
          return this.playerStateChange(VideoPlayerState.EXPANDED);
      case TrackingConsts.EVENT_NAME_PLAYER_COLLAPSE:
          return this.playerStateChange(VideoPlayerState.COLLAPSED);
      case TrackingConsts.EVENT_NAME_BUFFER_START:
          return this.bufferStart();
      case TrackingConsts.EVENT_NAME_BUFFER_FINISH:
          return this.bufferFinish();
      case TrackingConsts.EVENT_NAME_SKIP:
          return this.skipped();
      default:
          break;
      }
  }

  private impressionOccurred (): void {
    return this.adEvents.impressionOccurred();
  }

  private adUserInteraction (interaction: InteractionType): void {
    return this.mediaEvents.adUserInteraction(interaction);
  }

  private pause(): void {
    return this.mediaEvents.pause();
  }

  private resume(): void {
    return this.mediaEvents.resume();
  }

  private start(params: TrackEventType): void {
    const duration = params[TrackingConsts.EVENT_PARAM_DURATION] as number;
    const volume = params[TrackingConsts.EVENT_PARAM_VOLUME] as number;

    return this.mediaEvents.start(duration, volume);
  }

  private firstQuartile(): void {
    return this.mediaEvents.firstQuartile();
  }

  private midpoint(): void {
    return this.mediaEvents.midpoint();
  }

  private thirdQuartile(): void {
    return this.mediaEvents.thirdQuartile();
  }

  private complete(): void {
    return this.mediaEvents.complete();
  }

  private volumeChange(params: TrackEventType): void {
    const volume = params[TrackingConsts.EVENT_PARAM_VOLUME] as number;

    return this.mediaEvents.volumeChange(volume);
  }

  private playerStateChange(state: VideoPlayerState) {
    return this.mediaEvents.playerStateChange(state); 
  }

  private bufferStart(): void {
    return this.mediaEvents.bufferStart();
  }

  private bufferFinish(): void {
    return this.mediaEvents.bufferFinish();
  }

  private skipped(): void {
    return this.mediaEvents.skipped();
  }
}