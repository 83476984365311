import OmSdkAdTrackingService from './adTrackingService';
import OmidClient from './compiled';
import { TrackEventType } from './types/adTrackingService';
import { AdTrackingStartupParams } from './types/shared';

class FlutterWebAdTrackingService {
    public sdkInstance: OmSdkAdTrackingService;

    constructor() {
        this.sdkInstance = new OmSdkAdTrackingService(OmidClient.OmidSessionClient);   
    }

    startUp(params: AdTrackingStartupParams) {
        this.sdkInstance.startUp(params);
    }

    updatePlayerView() {
        this.sdkInstance.updatePlayerView();
    }

    trackEvent(params: TrackEventType) {
        this.sdkInstance.trackEvent(params);
    }

    shutdown() {
        this.sdkInstance.shutdown();
    }
}

declare global {
    interface Window {
        WebAdTrackingServiceNamespace: {
            FlutterWebAdTrackingService: { new(): FlutterWebAdTrackingService },
            loggerEnabled: boolean,
        };
    }
}

window.WebAdTrackingServiceNamespace = {
    FlutterWebAdTrackingService,
    loggerEnabled: false,
};
