export enum InteractionType {
  CLICK = 'click',
  INVITATION_ACCEPT = 'invitationAccept',
}

export enum ErrorType {
  GENERIC = 'generic',
  VIDEO = 'video',
  MEDIA = 'media',
}

export enum CreativeType {
  DEFINED_BY_JAVASCRIPT = 'definedByJavaScript',
  HTML_DISPLAY = 'htmlDisplay',
  NATIVE_DISPLAY = 'nativeDisplay',
  VIDEO = 'video',
  AUDIO = 'audio',
}

export enum ImpressionType {
  DEFINED_BY_JAVASCRIPT = 'definedByJavaScript',
  UNSPECIFIED = 'unspecified',
  LOADED = 'loaded',
  BEGIN_TO_RENDER = 'beginToRender',
  ONE_PIXEL = 'onePixel',
  VIEWABLE = 'viewable',
  AUDIBLE = 'audible',
  OTHER = 'other',
}