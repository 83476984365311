import AdTrackingService, { TrackEventType } from './types/adTrackingService';
import IOmidSessionClient from './types/omidSessionClient';
import { AdTrackingStartupParams } from './types/shared';
import { AccessMode } from './consts/accessModes';
import IPartner from './types/partner';
import IContext from './types/context';
import IAdSession from './types/adSession';
import { CreativeType, ImpressionType } from './consts/eventTypes';
import * as TrackingConsts from './consts/adTrackingConstants';
import AdEventTracker, { EventTracker } from './eventsTracker';

class OmSdkAdTrackingService implements AdTrackingService {
    private sessionClient: IOmidSessionClient | null = null;
    private adSession: IAdSession | null = null;
    private adEventTracker: EventTracker | null = null;

    constructor(sessionClient: IOmidSessionClient) {
        this.sessionClient = sessionClient;
    }

    private log(message?: string): void {
        if (!window.WebAdTrackingServiceNamespace.loggerEnabled) {
            return;
        }

        console.log('%c [WebOmSdkAdTracking]: ', 'background: #333; color: #bada55', message);
    }

    public startUp (params: AdTrackingStartupParams): void {
        if (!this.sessionClient) { 
           return;
        }

        try {
            const partner = this.createPartner(params);
            const context = this.createContext(params, partner);
    
            this.adSession = this.createAdSession(context);
    
            this.adSession.start();    
        } catch(e) {
            this.log(e as string);

            return;
        }

        const { AdEvents, MediaEvents, VastProperties } = this.sessionClient;

        const adEvents = new AdEvents(this.adSession);
        const mediaEvents = new MediaEvents(this.adSession);

        if (!adEvents || !mediaEvents) {
            return;
        }

        this.adEventTracker = new AdEventTracker(adEvents, mediaEvents);

        this.log('Ad Session created');

        const vastProperties = new VastProperties(false, 0, true, params.adPosition);

        this.adEventTracker.loaded(vastProperties);
    }

    private createPartner(params: AdTrackingStartupParams): IPartner {
        if (!this.sessionClient) {
            throw('Can not create partner. Session client is not created');
        }

        return new this.sessionClient.Partner(
            params.partnerName, 
            params.partnerSdkOrAppVersion
        );
    }

    private createContext(params: AdTrackingStartupParams, partner: IPartner): IContext {
        if (!this.sessionClient) {
            throw('Can not create context. Session client is not created');
        }

        const omSdkServiceWindow = window.top;
        const videoElement = document.querySelector('video');

        if (!videoElement || !omSdkServiceWindow) {
            throw('Can not create context. Video element does not exist');
        }

        const { VerificationScriptResource, Context } = this.sessionClient;

        const contentUrl = window.location.href.split(/[?#]/)[0] || '';

        const verificationResource = new VerificationScriptResource(
            params.jsResourceUri, 
            params.vendorId, 
            params.verificationParameters, 
            AccessMode.FULL
        );

        const context = new Context(partner, [verificationResource], contentUrl);
        
        context.setVideoElement(videoElement);
        context.setServiceWindow(omSdkServiceWindow);

        return context;
    }

    private createAdSession(context: IContext): IAdSession {
        if (!this.sessionClient) {
            throw('Can not create ad session. Session client is not created');
        }

        const adSession = new this.sessionClient.AdSession(context);

        if (!adSession.creativeType_) {
            adSession.setCreativeType(CreativeType.VIDEO);
            adSession.setImpressionType(ImpressionType.BEGIN_TO_RENDER);
        }

        if (!adSession.isSupported()) {
            throw('Can not start ad session. Session is not supported');
        }

        return adSession;
    }


    public updatePlayerView(): void {
        //
    }
    
    public trackEvent (params: TrackEventType): void {
        if (!this.adEventTracker) {
            return;
        }
        
        const eventName = params[TrackingConsts.EVENT_PARAM_NAME] as string;

        this.log(`Track event ${eventName}`);

        this.adEventTracker.trackEvent(eventName, params);
    }
    
    public shutdown (): void {
        if (!this.adSession) {
            this.log('Can not shut down. There is no active ad session.');

            return;
        }

        this.adSession.finish();
    }
}

export default OmSdkAdTrackingService;